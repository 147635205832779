import React from 'react';

import scrollToSection from './../../utils/scrollToSection';

const Footer = (props) => {
  return (
    <footer>
      <div id="footer-content">
        <div id="footer-logo-container" onClick={() => window.scrollTo(0, 0)}>
          <img id="footer-logo" src={props.logo} alt="Primal Logo" />
        </div>
        <div>
          <ul id="footer-nav">
            {props.navItems.map(item => (
              <li
                key={item}
                className="footer-nav-item"
                onClick={() => scrollToSection(item, props.history)}
              >
                {item}
              </li>
            ))}
          </ul>
        </div>

        <div id="footer-rect" />
        <div id="footer-social">
          {props.contents.socialIcons.map((Icon, i) => (<Icon key={i} className="social-icon" />))}
        </div>
        <div id="footer-copyright">
          <p>{props.contents.copyright}</p>
          <p style={{ fontWeight: 'bolder' }}>{props.contents.madeWith}</p>
        </div>
      </div>
    </footer>
  )
};

export default Footer;