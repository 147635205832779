import React from 'react';
import { FaChevronDown } from 'react-icons/fa';

import scrollToSection from './../../utils/scrollToSection';

const Hero = (props) => (
  <section id="Home">
    <div id="hero-bg" />
    <div id="hero-overlay" />
    <div id="hero-content">
      <div className="zoom-in">
        <h1 id="hero-title">{props.contents.title}</h1>
        <p id="hero-subtitle">{props.contents.subtitle}</p>
      </div>

      <div id="hero-btn" onClick={() => scrollToSection(props.contents.scrollTo)}>
        <FaChevronDown id="hero-icon" />
      </div>
    </div>
  </section>
);

export default Hero;