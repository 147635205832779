import PrimalLogo from './../assets/primal-logo.png';

//Clients
import IISI from './../assets/clients/iisi.png';
import CANIETI from './../assets/clients/canieti.webp';
import CAPIM from './../assets/clients/capim.png';
import INA from './../assets/clients/ina.png';
import TASI from './../assets/clients/tasi.png';
import ORIENS from './../assets/clients/oriens.png';
import TOP from './../assets/clients/top.png';
import TORO from './../assets/clients/toro.png';
import YBIRA from './../assets/clients/ybira.png';
import BRP from './../assets/clients/brp.png';
import SNOWBALL from './../assets/clients/snowball.png';
import SAP from './../assets/clients/SAP.webp';
import JABIL from './../assets/clients/jabil.png';
import MIGESA from './../assets/clients/migesa.png';
import DESARROLLOECONOMICO from './../assets/clients/desarrollo-economico.png';

//Services
import Cybersecurity from './../assets/services/cybersecurity.webp';
import Software from './../assets/services/software.webp';
import Mobile from './../assets/services/mobile.webp';
import Industry from './../assets/services/industry.webp';
import AI from './../assets/services/ai.webp';
import Outsourcing from './../assets/services/outsource.webp';


//Technologies
import HTML from './../assets/software/html-5.svg';
import CSS from './../assets/software/css-3.svg';
import SASS from './../assets/software/sass.svg';
import JS from './../assets/software/javascript.svg';
import JQuery from './../assets/software/jquery.svg';
import React from './../assets/software/react.svg';
import Vue from './../assets/software/vue.svg';
import Angular from './../assets/software/angular.svg';

import NodeJS from './../assets/software/nodejs.svg';
import PHP from './../assets/software/php.svg';
import Laravel from './../assets/software/laravel.svg';
import Python from './../assets/software/python.svg';
import Flask from './../assets/software/flask.svg';
import Django from './../assets/software/django.svg';
import Go from './../assets/software/golang.svg';

import Java from './../assets/software/java.svg';
import Kotlin from './../assets/software/kotlin.svg';
import Swift from './../assets/software/swift.svg';
import Ionic from './../assets/software/ionic.svg';
import ReactNative from './../assets/software/react-native.svg';
import Flutter from './../assets/software/flutter.svg';

import GoogleCloud from './../assets/software/google-cloud.svg';
import AzureCloud from './../assets/software/azure.svg';
import AWS from './../assets/software/aws.svg';
import Docker from './../assets/software/docker.svg';

import MySQL from './../assets/software/mysql.svg';
import PostgreSQL from './../assets/software/postgresql.svg';
import MongoDb from './../assets/software/mongodb.svg';

import Tensoflow from './../assets/software/tensorflow.svg';
import PyTorch from './../assets/software/pytorch.svg';

//Icons
import { MdLocationOn, MdEmail } from 'react-icons/md';
import { FaFacebookSquare, FaWhatsapp, FaInstagram, FaTwitterSquare, FaLinkedin } from 'react-icons/fa';

const contents = {
  logo: PrimalLogo,
  navItems: ['About', 'Services', 'Clients', 'Contact'],
  hero: {
    title: "Transforming Companies",
    subtitle: "The path to the future",
    scrollTo: 'About'
  },
  about: {
    title: "About Us",
    cite: "It is not the strongest of the species that survives, nor the most intelligent that survives. It is the one that is most adaptable to change.",
    author: "– Charles Darwin",
    mision: "We are a company with a clear future view, with a high adaptability that is dedicated to the development of software supporting the digital transformation of companies.",
    vision: "We transform the industry through technological development to generate globally competitive companies."
  },
  services: {
    title: "Services",
    servicesItems: [
      { title: 'Cybersecurity', img: Cybersecurity, link: "cybersecurity" },
      { title: 'Custom Software & Outsourcing', img: Software, link: "software" },
      //{ title: 'Outsourcing ', img: Outsourcing, link: "outsourcing" },
      { title: 'Industry 4.0', img: Industry, link: "industry" },
      { title: 'Artifitial Intelligence', img: AI, link: "ai" },
    ]
  },
  clients: {
    title: "Our clients",
    clientsItems: [
      { title: "SAP", icon: SAP, link: "https://www.snowball.mx/" },
      { title: "BRP", icon: BRP, link: null },
      { title: "Snowball", icon: SNOWBALL, link: null },
      { title: "CANIETI", icon: CANIETI, link: null },
      //{ title: "MIGESA", icon: MIGESA, link: null },
      { title: "JABIL", icon: JABIL, link: null },
      { title: "CAPIM", icon: CAPIM, link: null },
      { title: "IISI", icon: IISI, link: null },
      { title: "INA", icon: INA, link: null },
      { title: "TORO", icon: TORO, link: null },
      { title: "DESARROLLOECONOMICO", icon: DESARROLLOECONOMICO, link: null },
      { title: "YBIRA", icon: YBIRA, link: null },
      
      //{ title: "TASI", icon: TASI, link: null },
      //{ title: "ORIENS", icon: ORIENS, link: null },
      //{ title: "TOP", icon: TOP, link: null },
    ]
  },
  newsletter: {
    title: "Looking for exclusive content?",
    description: "Subscribe to our newsletter and stay informed about the latest news about us, software and technology.",
    comment: "*Trust us, we'll never send you spam"
  },
  contact: {
    title: "Contact Us",
    description: "Do you need help to create your application? Contact us to discuss prices and advise you about what is best for you and your software idea",
    contactItems: [
      { Icon: FaWhatsapp, title: 'Call Us', info: '656 422 5026' },
      { Icon: MdEmail, title: 'Email', info: 'info@oneprimal.com' },
      { Icon: MdLocationOn, title: 'Address', info: 'AVE. VALLE DE JUAREZ 6445' },
    ]
  },
  footer: {
    socialIcons: [FaFacebookSquare, FaInstagram, FaTwitterSquare, FaLinkedin],
    copyright: "© PRIMAL TECHNOLOGIES 2024",
    madeWith: "Made by the best in IT solutions."
  },
  
  software: {
    title: "Custom Software",
    img: "https://images.unsplash.com/photo-1510915228340-29c85a43dcfe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80",
    contents: [
      {
        title: 'About',
        body: [
          "Primal provides developers with years of experience building custom software to other companies, below are the software that we provide.",
        ]
      },
      {
        title: "Developers with agile mindset",
        body: [
          "Over 50% of our business comes from clients who have had poor experience working with other development teams in the past and want us to take over development or redo their project. The core value of our service comes from developers with Agile Mindset.",
          "We found from our outsourcing business that a developer with business value perspective is much more creative and positive when working with clients. We called it Agile Mindset with it, our developers are easy communicated, more positive to learn, improve, and to solve software problems. Their collaboration with clients goes smooth and effective.",
          "WE PROVIDE FULL STACK DEVELOEPRS WITH 3~10 YEARS' EXPERIENCE IN THE TECHNOLOGIES BELOW, STRICTLY NO JUNIOR GUYS OR FREELANCERS."
        ]
      }
    ],
    cards: [
      {
        icon: null,
        title: "Web Application",
        body: "Primal can guide you through the digital jorney and build web applications tailored to your needs and expectations. By using the lastest technologies, we put functionality, performance, design and usability at the heart of your enterprise development."
      },
      {
        icon: null,
        title: "Mobile App",
        body: "Primal mobile development center offers one-stop service from user experience strategy consulting, interface design to custom development. Over the past 9 years, we have accumulated a wealth of project experience in different industries, including enterprise management, education, travel, social networking, entertainment, sports, music, finance, news, health care, and photography, etc."
      },
      {
        icon: null,
        title: "Client Relationship Management (CRM)",
        body: "Using a bespoke CRM system can help companies transfer the way they interact with their customers. Without the need to purchase the license of off-the-shelf CRM system, you can add functions based on your specific business requirements, so it can easily scale with any changes to your business process and targets."
      },
      {
        icon: null,
        title: "Business Data Analysis",
        body: "Through professional business data analysis service, Primal helps enterprises collect, process, analyze and mine a large amount of data generated in production, sales and other links and then convert them into commercial value, enabling enterprises to optimize production process, improve production efficiency and fully perceive user behavior, so as to help enterprises continuously improve products/services and maintain competitive advantage."
      },
      {
        icon: null,
        title: "AR/VR/MR",
        body: "Primal provides strong support for customers in their product and market development by applying technologies as Mix Reality(MR), Virtual Reality (VR), Augmented Reality (AR) and 3D modeling."
      },
      {
        icon: null,
        title: "E-Commerce System",
        body: "We have already built e-business platform of different sizes in electronic products, FMCG, auto & spare parts, luxury goods, agriculture and other fields, and accumulated a wealth of custom development experience in such links as account management, product display, shopping cart management, trading platform, inventory management, logistics tracking and evaluation system."
      },
      {
        icon: null,
        title: "Artificial Intelligence",
        body: "With deep learning and machine learning, AI can become smarter over time, thus increasing a business' efficiency. AI will also significantly reduce the probability of human error and study historical data to cut costs."
      }
    ],
    technologies: [
      {
        title: "Frontend",
        icons: [HTML, CSS, SASS, JS, JQuery, React, Vue, Angular]
      },
      {
        title: "Backend",
        icons: [NodeJS, PHP, Laravel, Python, Flask, Django, Go]
      },
      {
        title: "Cross-platform: Android & iOS",
        icons: [Java, Kotlin, Swift, Ionic, ReactNative, Flutter]
      },
      {
        title: "Cloud Services",
        icons: [GoogleCloud, AzureCloud, AWS, Docker]
      },
      {
        title: "Data Management",
        icons: [MySQL, PostgreSQL, MongoDb]
      },
      {
        title: "Artificial Intelligence",
        icons: [Tensoflow, PyTorch, GoogleCloud, AWS, AzureCloud]
      }
    ]
  },
  outsourcing: {
    title: "Outsourcing",
    img: "https://ak1.picdn.net/shutterstock/videos/19102831/thumb/1.jpg",
    contents: [
      {
        title: "About",
        body: [
          "Primal is your great partner when looking to expand capacity of your development teams. We provide competent onshore/offshore Agile Developers that are not only experts in their technical domain to consistently deliver high quality code, but also dedicated and efficient to offer solutions for the problems you encountered. They can capture your business requirements, carefully consider the best technical solution, then code and deliver quality software application/functional modules that help to quickly solve your problems or make your ideas happen.",
        ]
      },
      {
        title: "Developers with agile mindset",
        body: [
          "Over 50% of our business comes from clients who have had poor experience working with other development teams in the past and want us to take over development or redo their project. The core value of our service comes from developers with Agile Mindset.",
          "We found from our outsourcing business that a developer with business value perspective is much more creative and positive when working with clients. We called it Agile Mindset with it, our developers are easy communicated, more positive to learn, improve, and to solve software problems. Their collaboration with clients goes smooth and effective.",
          "WE PROVIDE FULL STACK DEVELOEPRS WITH 3~10 YEARS' EXPERIENCE IN THE TECHNOLOGIES BELOW, STRICTLY NO JUNIOR GUYS OR FREELANCERS."
        ]
      }
    ],
    technologies: [
      {
        title: "Frontend",
        icons: [HTML, CSS, SASS, JS, JQuery, React, Vue, Angular]
      },
      {
        title: "Backend",
        icons: [NodeJS, PHP, Laravel, Python, Flask, Django, Go]
      },
      {
        title: "Cross-platform: Android & iOS",
        icons: [Java, Kotlin, Swift, Ionic, ReactNative, Flutter]
      },
      {
        title: "Cloud Services",
        icons: [GoogleCloud, AzureCloud, AWS, Docker]
      },
      {
        title: "Data Management",
        icons: [MySQL, PostgreSQL, MongoDb]
      },
      {
        title: "Artificial Intelligence",
        icons: [Tensoflow, PyTorch, GoogleCloud, AWS, AzureCloud]
      }
    ]
  },
  mobile: {
    title: "Mobile",
    img: "https://images.unsplash.com/photo-1512941937669-90a1b58e7e9c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80",
    contents: [
      {
        title: "About",
        body: [
          "Here, we have expertise in creating multiplatform mobile app solutions. We offer custom app developmet that runs smoothly on multi-platforms.",
          "With strong strength in the field of software technology, PRIMAL has gradually established its perspective and ability in interactive design and visual design, and by adequate study of the market and users in combination with business requirements and technical feasibility, PRIMAL has brought innovative and sustainable solutions as well as smooth and enjoyable app experience for its customers and users."
        ]
      }
    ]
  },
  ai: {
    title: "Artificial Intelligence",
    img: AI,
    contents: [
      {
        title: "About",
        body: [
          "Primal Technologies is a cutting-edge tech company that specializes in the development and implementation of autonomous systems and intelligent automation solutions. The company is at the forefront of revolutionizing various industries by integrating advanced robotics, artificial intelligence, and machine learning technologies into their products and services.",
          "Primal Technologies' core focus lies in creating autonomous systems that streamline processes, enhance efficiency, and reduce human intervention across a wide range of applications. These applications span industries such as manufacturing, logistics, healthcare, agriculture, transportation, and beyond.",
          "The company's team comprises experts in robotics, computer science, engineering, and data analytics who collaborate to design and deploy innovative automation solutions tailored to meet the specific needs and challenges of their clients.",
          "Primal Technologies' portfolio includes a diverse range of products and services, including robotic process automation (RPA) software, industrial automation systems, smart sensors, and predictive analytics platforms.",
          "Through continuous research and development, Primal Technologies remains committed to pushing the boundaries of technological innovation, driving forward the evolution of automation, and empowering businesses to unlock new levels of productivity, precision, and profitability in the digital age."
        ]
      }
    ]
  },
  cybersecurity: {
    title: "Cybersecurity",
    img: Cybersecurity,
    contents: [
      {
        title: "About",
        body: [
          "Primal Technologies specializes in providing robust cybersecurity solutions designed to protect businesses from digital threats. Our comprehensive approach includes risk assessment, threat detection, and incident response to ensure the highest level of security for our clients.",
          "Our team of cybersecurity experts leverages the latest technologies and methodologies to safeguard critical data and systems. From network security and encryption to compliance and security training, we offer a full spectrum of services to meet the unique needs of each client.",
          "Primal Technologies' cybersecurity solutions are tailored to address the evolving landscape of cyber threats. We implement advanced security measures to prevent unauthorized access, detect potential vulnerabilities, and respond to security incidents swiftly and effectively.",
          "Our commitment to continuous improvement and innovation in cybersecurity ensures that our clients are always protected against emerging threats. We strive to build resilient security infrastructures that support the integrity, confidentiality, and availability of business operations.",
          "Through proactive monitoring, threat intelligence, and strategic planning, Primal Technologies helps businesses maintain a secure environment, allowing them to focus on their core activities with peace of mind."
        ]
      }
    ]
  },
  industry: {
    title: "Industry 4.0",
    img: Industry,
    contents: [
      {
        title: "About",
        body: [
          "Atumatation is a cutting-edge tech company that specializes in the development and implementation of autonomous systems and intelligent automation solutions. The company is at the forefront of revolutionizing various industries by integrating advanced robotics, artificial intelligence, and machine learning technologies into their products and services.",
          "Atumatation's core focus lies in creating autonomous systems that streamline processes, enhance efficiency, and reduce human intervention across a wide range of applications. These applications span industries such as manufacturing, logistics, healthcare, agriculture, transportation, and beyond.",
          "The company's team comprises experts in robotics, computer science, engineering, and data analytics who collaborate to design and deploy innovative automation solutions tailored to meet the specific needs and challenges of their clients.",
          "Atumatation's portfolio includes a diverse range of products and services, including autonomous vehicles, robotic process automation (RPA) software, industrial automation systems, smart sensors, and predictive analytics platforms.",
          "Through continuous research and development, Atumatation remains committed to pushing the boundaries of technological innovation, driving forward the evolution of automation, and empowering businesses to unlock new levels of productivity, precision, and profitability in the digital age."
        ]
      }
    ]
  }
};

export default contents;