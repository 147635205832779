import React from 'react';

const SoftwareOptions = (props) => {
  if (props.cards) {
    return (
      <div id="Cards">
        <div id="card-contents">
          {props.cards.map((item, i) => {
            return (
              <div key={i} className="card-item">
                <div className="card-title" style={{ backgroundColor: (i % 2 === 0) ? "#96eaff" : "#97a8ff" }}>
                  <h3>{item.title}</h3>
                </div>
                <div className="card-body">
                  <p>{item.body}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  return null;
}

export default SoftwareOptions;