import React from 'react';

import scrollToSection from './../../utils/scrollToSection';

const NavMenu = (props) => {
  return (
    <nav>
      <ul id="nav-container">
        {props.navItems.map(item => (
          <li
            key={item}
            className="nav-item"
            onClick={() => scrollToSection(item, props.history)}
          >
            {item}
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default NavMenu;