import React from 'react';

const Newsletter = (props) => {
  return (
    <section id="Newsletter">
      <div id="newsletter-content">
        <h2>{props.contents.title}</h2>
        <p id="newsletter-description">
          {props.contents.description}
        </p>
        <form>
          <input id="email-input" type="email" placeholder="Enter your email" required />
          <input className="btn-contained" type="submit" value="Subscribe" />
        </form>
        <p>{props.contents.comment}</p>
      </div>
    </section>
  );
}

export default Newsletter