import React, { useState } from 'react';
import { MdMenu } from 'react-icons/md';
import scrollToSection from './../../utils/scrollToSection';

const Drawer = (props) => {
  const [show, setShow] = useState(false);

  const toogleDrawer = () => {
    return setShow(!show);
  }

  return (
    <div>
      <MdMenu id="hamburger-icon" onClick={toogleDrawer} />
      {show ? <div id="drawer-overlay" onClick={toogleDrawer} /> : null}
      <nav style={{ transform: (show) ? 'translateX(0rem)' : 'translateX(30rem)' }}>
        <ul id="drawer-container">
          {props.navItems.map(item => (
            <li
              key={item}
              className="drawer-item"
              onClick={() => scrollToSection(item, props.history)}
            >
              {item}
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
}

export default Drawer;