import React from 'react';

const Header = (props) => (
  <header>
    <div id="header-content">
      <div id="header-logo-container" onClick={() => props.history.push('/')}>
        <img id="header-logo" src={props.logo} alt="Primal Logo" />
      </div>
      {props.children}
    </div>
  </header>
);

export default Header;