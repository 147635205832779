import React from 'react';
import './../../css/home.css';

import MainLayout from './../components/MainLayout';
import Hero from './../components/Landing/Hero';
import About from './../components/Landing/About';
import Services from './../components/Landing/Services';
import Clients from '../components/Landing/Clients';
import Newsletter from './../components/Landing/Newsletter';
import Contact from './../components/Contact';

import contents from './../utils/contents';


const Home = (props) => {
  return (
    <MainLayout {...props}>
      <Hero {...props} contents={contents.hero} />
      <About {...props} contents={contents.about} />
      <Services {...props} contents={contents.services} />
      <Clients {...props} contents={contents.clients} variation={0} />
      <Newsletter {...props} contents={contents.newsletter} />
      <Contact {...props} contents={contents.contact} />
    </MainLayout>
  )
};

export default Home;