import React from 'react';
import './../../../css/mainlayout.css';

import Header from './Header';
import Footer from './Footer';
import NavMenu from './NavMenu';
import Drawer from './Drawer';

import useWindowSize from './../../utils/useWindowSize';
import contents from './../../utils/contents';

const MainLayout = ({ children, ...props }) => {
  const [width, height] = useWindowSize();

  return (
    <div id="main-layout">
      <Header {...props} logo={contents.logo}>
        {(width >= 750) ? <NavMenu {...props} navItems={contents.navItems} /> : <Drawer {...props} navItems={contents.navItems} />}

      </Header>
      {children}
      <Footer {...props} logo={contents.logo} navItems={contents.navItems} contents={contents.footer} />
    </div>
  )
};

export default MainLayout;