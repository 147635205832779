import React from 'react';

const Banner = (props) => {
  return (
    <div id="Banner" style={{ backgroundImage: `url(${props.img})` }}>
      <h2>{props.title}</h2>
      <div className="service-rect" />
    </div>
  );
}

export default Banner;