import React from 'react';
import { useParams } from 'react-router-dom';
import '../../css/service.css';

import MainLayout from './../components/MainLayout';
import Banner from './../components/Service/Banner';
import ServiceContents from './../components/Service/ServiceContents';
import SoftwareOptions from './../components/Service/SoftwareOptions';
import Technologies from './../components/Service/Technologies';
import Contact from './../components/Contact';
import Divider from './../components/Divider';

import content from './../utils/contents';

const Service = (props) => {
  window.scrollTo(0, 0);
  const { service } = useParams();
  const current = content[service];

  if (current) {
    return (
      <MainLayout {...props}>
        <Banner title={current.title} img={current.img} />
        <ServiceContents contents={current.contents} />
        <SoftwareOptions cards={current.cards} />
        <Technologies technologies={current.technologies} />
        <Divider />
        <Contact contents={content.contact} />
      </MainLayout>
    );
  }
  return null;
}

export default Service;