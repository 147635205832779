import React, { Component } from 'react';

import contents from './../utils/contents';

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      'signup[name]': '',
      'signup[email]': '',
      'signup[mensaje]': ''
    };
    this.callbackName = 'jsonp_callback_' + Math.round(100000 * Math.random());
    this.validEmail = /.+@.+\..+/
  }

  serializeState = () => {
    const keys = [
      'signup[name]',
      'signup[email]',
      'signup[mensaje]'
    ];
    let q = [];
    for (let i = 0; i < keys.length; i++) {
      q.push(`${keys[i]}=${encodeURIComponent(this.state[keys[i]])}`);
    }
    q.push(`utf8=%E2%9C%93`)
    return q.join('&');
  }

  formatUrl = () => {
    const BASE = "https://gem.godaddy.com/signups/subscribe/00d35bdb1d6c41d0879bd39ff82837a1";
    return `${BASE}.json?callback=${this.callbackName}&${this.serializeState()}`;
  }

  validate = () => {
    const keys = Object.keys(this.state);
    for (let i = 0; i < keys.length; i++) {
      if (typeof this.state[keys[i]] !== 'string') return false;
      if (this.state[keys[i]].length <= 0) return false
    }
    return this.validEmail.test(this.state['signup[email]']);
  }

  onSubmit = (e) => {
    e.preventDefault();
    if (this.validate()) {
      const url = this.formatUrl();
      const options = {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json'
        },
      };
      fetch(url, options)
        .then(resp => {
          console.log(resp);
        })
        .catch(err => console.log(err))
    }
  }

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  render() {
    return (
      <section id="Contact">
        <div id="contact-content">
          <div className="contact-item">
            <h2>{this.props.contents.title}</h2>
            <div className="contact-rect" />
            <p id="contact-description">{contents.contact.description}</p>
            <div id="contact-icons">
              {this.props.contents.contactItems.map(({ title, info, Icon }) => {
                return (
                  <div key={title} className="info-item">
                    <Icon className="icon" />
                    <div className="icon_text">
                      <p style={{ fontWeight: 'bold' }}>{title}</p>
                      <p>{info}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="contact-item">
            <form id="contact_form" onSubmit={this.onSubmit}>
              <input
                className="form-item fade-in-down"
                name="signup[name]"
                type="text"
                placeholder="Name"
                onChange={this.onChange}
                required
              />
              <input
                className="form-item fade-in-down"
                name="signup[email]"
                type="email"
                placeholder="Email"
                onChange={this.onChange}
                required
              />
              <textarea
                className="form-item fade-in-down"
                name="signup[mensaje]"
                placeholder="Message"
                onChange={this.onChange}
                cols="30"
                rows="6"
                required
              ></textarea>
              <input
                className="btn-contained form-item fade-in-down"
                type="submit"
                value="Submit Message"
              />
            </form>
          </div>
        </div>
      </section>
    );
  }
}

export default Contact;