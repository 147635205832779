import React from 'react';

const Technologies = (props) => {
  if (props.technologies) {
    return (
      <div id="Technologies">
        <div id="technologies-content">
          {props.technologies.map(type => {
            return (
              <div key={type.title} className="tech-type">
                <h3>{type.title}</h3>
                <div className="techs">
                  {type.icons.map(tech => {
                    return (
                      <div className="tech-container">
                        <img className="tech-svg" src={tech} alt="Technology logo" />
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  return null;
}

export default Technologies;