import React from 'react';

const Services = (props) => (
  <section id="Services">
    <div id="services-content">
      <h2>{props.contents.title}</h2>
      <div id="services-list">
        {props.contents.servicesItems.map(item => {
          return (
            <div
              key={item.title}
              className="service-item zoom-in-bounce"
              onClick={() => props.history.push(`/services/${item.link}`)}
              style={{ backgroundImage: `url(${item.img})` }}
            >
              <p>{item.title}</p>
            </div>
          );
        })}
      </div>
    </div>
  </section>
);

export default Services;