import React from 'react';

const About = (props) => (
  <section id="About">
    <div id="about-content">
      <h2 className="fade-in-up">{props.contents.title}</h2>
      <div id="about-content">
        <div className="item fade-in-left">
          <p id="focus-text">{props.contents.cite}</p>
          <p id="cite">{props.contents.author}</p>
        </div>
        <div className="item fade-in-left">
          <p>{props.contents.mision}</p>
        </div>
        <div className="item fade-in-left">
          <p>{props.contents.vision}</p>
        </div>
      </div>
    </div>
  </section>
);

export default About;