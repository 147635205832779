import React from 'react';

const Clients = (props) => {
  return (
    <section id="Clients">
      <div id="clients-content">
        <h2>{props.contents.title}</h2>
        <div className="clients-rect" />
        <div id="clients-list">
          {props.contents.clientsItems.map(client => {
            return (
              <div key={client.title}>
                <img className="client-logo" key={client.title} src={client.icon} alt={client.title} />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default Clients;