import React from 'react';

const ServiceContents = (props) => {
  return (
    <div id="Contents">
      <div id="service-contents">
        {props.contents.map((content, i) => {
          return (
            <div key={i}>
              <h3 className="content-title">{content.title}</h3>
              {content.body.map((paragraph, i) => {
                return <p key={i} className="content-body">{paragraph}</p>
              })}
              {content.bullets ? (
                <ul className="content-bullets">
                  {content.bullets.map((bullet, i) => {
                    return <li key={i}>{bullet}</li>
                  })}
                </ul>
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ServiceContents;